@import '../../scss/breakpoints';

@keyframes pulse {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes pulse-lg {
  0% {
    transform: scale(2);
  }

  50% {
    transform: scale(2.25);
  }

  100% {
    transform: scale(2);
  }
}

.wrapper {
  width: 6rem;
}

.animated {
  display: inline-block;
  animation-name: pulse;
  transition-property: transform;
  transition-duration: 1s;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @include lg {
    animation-name: pulse-lg;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 40px;
  color: rgb(255 255 255 / 60%);
  transition: background-color .4s ease-in-out;
  cursor: pointer;

  &:hover, &:focus {
    &::after {
      content: " ";
      background: rgb(255 255 255 / 10%);
      border-radius: 6rem;;
      height: 6rem;
      position: absolute;
      width: 6rem;
    }
  }
}


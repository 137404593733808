$xs-width: 320px;
$sm-width: 576px;
$md-width: 768px;
$lg-width: 1024px;

@mixin xs {
  @media (min-width: #{$xs-width}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$sm-width}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$md-width}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

@import 'https://fonts.googleapis.com/css2?family=Inder&family=Inria+Serif&family=Libre+Caslon+Display&family=Ruge+Boogie&family=Urbanist:wght@500;600&family=Yellowtail&display=swap';

body {
  padding: 0;
  margin: 0;
}

// Swiper Variables
/* stylelint-disable-next-line scss/dollar-variable-pattern */
:root {
  --swiper-navigation-color: #545454;
  --swiper-pagination-right: 10%;
}


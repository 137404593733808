@import '../../scss/breakpoints';
@import '../../scss/constants';

@keyframes move-clouds {
  0% {
    left: -200px;
  }

  100% {
    left: 100%;
  }
}

$desktop-nav-height: 70%;
$mobile-nav-height: 10%;

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/sky.jpg') center center/cover;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.nav-wrapper {
  width: 100%;
  position: fixed;
  max-height: 2rem;
  top: 0;
}

.cloud {
  width: 200px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: move-clouds 20s linear infinite;

  @include lg {
    animation: move-clouds 30s linear infinite;
  }

  z-index: -1;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: .5rem;
  width: 80%;
  height: $desktop-nav-height + $mobile-nav-height;
  background-color: #fff;
  border: solid $content-border-width rgba(#fff, 0.3);
  margin-top: $mobile-nav-height; // To push it back down

  @include md {
    height: $desktop-nav-height;
    margin-top: unset;
  }

  .circleWrapper {
    position: absolute;
    bottom: 0;
    right: 0;

    @include md {
      right: 0;
      top: 0;
    }

    @include lg {
      right: 0;
      top: 0;
    }
  }

  &::before {
    content: ' ';
    position: absolute;
    z-index: -1;
    inset: -$content-border-width;
    filter: blur(.5rem);
    border: inherit;
    border-color: transparent;
    background: inherit;
    background-clip: border-box;

  }
}

.about-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  .portrait {
    border: 1px solid #fff;
    max-width: 50%;
    height: auto;
    margin: 4px;
    border-radius: 50%;

    @include sm {
      max-width: 30%;
    }
    
    @include md {
      max-width: 25%;
    }
  }
}

.about {
  display: flex;
  flex-direction: column;
  font-family: 'Libre Caslon Display', serif;

  @include md {
    max-width: 40%;
  }

  .title { 
    margin: 1rem 2rem 0;
    text-align: center;
    display: inline-block;
    align-self: center;
    font-weight: 600;

    &::after {
      content: ' ';
      display: block;
      margin-top: .5rem;
      width: 100%;
      height: 1px;
      background-color: #000;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #000;
  }
}

.contact-container {
  display: flex;
  width: 60%;
  justify-content: space-between;

  @include md {
    max-width: 20%;
  }
}

.arrows {
  @include md {
    position: absolute;
    bottom: 5%;
  }
}
